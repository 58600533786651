module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"commonmark":true,"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"icon":"<svg aria-hidden=\"true\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\"><path d=\"M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71\"/><path d=\"M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71\"/></svg>","className":"remark-autolink-headers__anchor"}},{"resolve":"/opt/build/repo/src/features/brain-notes/gatsby-remark-double-brackets-link.ts","options":{"stripBrackets":true,"titleToURL":{"prefix":"notes"}}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1380,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-vscode","options":{"extensionDataDirectory":"/opt/build/repo/__deps__/vscode-extensions","injectStyles":false,"extensions":[{"identifier":"hackwaly.ocaml","version":"0.6.43"},{"identifier":"sdras.night-owl","version":"1.1.3"},{"identifier":"2gua.rainbow-brackets","version":"0.0.6"},{"identifier":"fwcd.kotlin","version":"0.2.10"},{"identifier":"prisma.vscode-graphql","version":"0.2.2"},{"identifier":"benfradet.vscode-unison","version":"0.3.0"}]}}],"remarkPlugins":[null],"defaultLayouts":{"posts":"/opt/build/repo/src/layouts/PostLayout.tsx","speaking":"/opt/build/repo/src/layouts/TalkNoteLayout.tsx","notes":"/opt/build/repo/src/layouts/NoteLayout.tsx"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"haspar.us","short_name":"haspar.us","start_url":"/","icon":"src/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"03ddcee63309eb0cbf3c06053d93e1e2"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
