// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---content-notes-adding-jest-custom-matchers-in-type-script-mdx": () => import("./../../../content/notes/Adding Jest Custom Matchers in TypeScript.mdx" /* webpackChunkName: "component---content-notes-adding-jest-custom-matchers-in-type-script-mdx" */),
  "component---content-notes-advent-of-code-2020-2020-12-04-md": () => import("./../../../content/notes/advent-of-code-2020/2020-12-04.md" /* webpackChunkName: "component---content-notes-advent-of-code-2020-2020-12-04-md" */),
  "component---content-notes-advent-of-code-2020-advent-of-code-2020-md": () => import("./../../../content/notes/advent-of-code-2020/advent-of-code-2020.md" /* webpackChunkName: "component---content-notes-advent-of-code-2020-advent-of-code-2020-md" */),
  "component---content-notes-advent-of-code-2020-aoc-2020-unison-md": () => import("./../../../content/notes/advent-of-code-2020/aoc-2020-unison.md" /* webpackChunkName: "component---content-notes-advent-of-code-2020-aoc-2020-unison-md" */),
  "component---content-notes-advent-of-code-2020-day-1-report-repair-md": () => import("./../../../content/notes/advent-of-code-2020/day-1-report-repair.md" /* webpackChunkName: "component---content-notes-advent-of-code-2020-day-1-report-repair-md" */),
  "component---content-notes-contrast-triangle-mdx": () => import("./../../../content/notes/Contrast Triangle.mdx" /* webpackChunkName: "component---content-notes-contrast-triangle-mdx" */),
  "component---content-notes-developer-not-a-blogger-md": () => import("./../../../content/notes/Developer, not a blogger.md" /* webpackChunkName: "component---content-notes-developer-not-a-blogger-md" */),
  "component---content-notes-languages-for-engineering-md": () => import("./../../../content/notes/languages-for-engineering.md" /* webpackChunkName: "component---content-notes-languages-for-engineering-md" */),
  "component---content-notes-overengineering-a-personal-website-md": () => import("./../../../content/notes/Overengineering a personal website.md" /* webpackChunkName: "component---content-notes-overengineering-a-personal-website-md" */),
  "component---content-notes-procedular-generation-md": () => import("./../../../content/notes/Procedular Generation.md" /* webpackChunkName: "component---content-notes-procedular-generation-md" */),
  "component---content-notes-spoiled-by-type-script-md": () => import("./../../../content/notes/Spoiled by TypeScript.md" /* webpackChunkName: "component---content-notes-spoiled-by-type-script-md" */),
  "component---content-notes-taking-notes-md": () => import("./../../../content/notes/Taking Notes.md" /* webpackChunkName: "component---content-notes-taking-notes-md" */),
  "component---content-notes-tiny-alternative-to-storybook-mdx": () => import("./../../../content/notes/Tiny Alternative to Storybook.mdx" /* webpackChunkName: "component---content-notes-tiny-alternative-to-storybook-mdx" */),
  "component---content-notes-type-script-exercises-and-challenges-md": () => import("./../../../content/notes/TypeScript Exercises and Challenges.md" /* webpackChunkName: "component---content-notes-type-script-exercises-and-challenges-md" */),
  "component---content-notes-wcag-20-guideline-1-4-distinguishable-md": () => import("./../../../content/notes/wcag20-guideline-1-4-distinguishable.md" /* webpackChunkName: "component---content-notes-wcag-20-guideline-1-4-distinguishable-md" */),
  "component---content-notes-writing-rm-because-of-windows-mdx": () => import("./../../../content/notes/writing-rm-because-of-windows.mdx" /* webpackChunkName: "component---content-notes-writing-rm-because-of-windows-mdx" */),
  "component---content-posts-deliver-mdx": () => import("./../../../content/posts/deliver.mdx" /* webpackChunkName: "component---content-posts-deliver-mdx" */),
  "component---content-posts-garden-mdx": () => import("./../../../content/posts/_garden.mdx" /* webpackChunkName: "component---content-posts-garden-mdx" */),
  "component---content-posts-lines-of-code-mdx": () => import("./../../../content/posts/lines-of-code.mdx" /* webpackChunkName: "component---content-posts-lines-of-code-mdx" */),
  "component---content-posts-reading-mdx": () => import("./../../../content/posts/reading.mdx" /* webpackChunkName: "component---content-posts-reading-mdx" */),
  "component---content-posts-reasonable-language-choice-mdx": () => import("./../../../content/posts/reasonable-language-choice.mdx" /* webpackChunkName: "component---content-posts-reasonable-language-choice-mdx" */),
  "component---content-posts-refinement-types-mdx": () => import("./../../../content/posts/refinement-types.mdx" /* webpackChunkName: "component---content-posts-refinement-types-mdx" */),
  "component---content-posts-syntax-test-hidden-mdx": () => import("./../../../content/posts/syntax-test.hidden.mdx" /* webpackChunkName: "component---content-posts-syntax-test-hidden-mdx" */),
  "component---content-posts-theme-ui-components-mdx": () => import("./../../../content/posts/theme-ui__components.mdx" /* webpackChunkName: "component---content-posts-theme-ui-components-mdx" */),
  "component---content-posts-why-did-you-render-mdx": () => import("./../../../content/posts/why-did-you-render.mdx" /* webpackChunkName: "component---content-posts-why-did-you-render-mdx" */),
  "component---content-posts-you-deserve-more-than-proptypes-mdx": () => import("./../../../content/posts/you-deserve-more-than-proptypes.mdx" /* webpackChunkName: "component---content-posts-you-deserve-more-than-proptypes-mdx" */),
  "component---content-speaking-building-a-branded-styling-dsl-in-typescript-mdx": () => import("./../../../content/speaking/building-a-branded-styling-dsl-in-typescript.mdx" /* webpackChunkName: "component---content-speaking-building-a-branded-styling-dsl-in-typescript-mdx" */),
  "component---content-speaking-linter-overload-mdx": () => import("./../../../content/speaking/linter-overload.mdx" /* webpackChunkName: "component---content-speaking-linter-overload-mdx" */),
  "component---content-speaking-matryoshka-code-mdx": () => import("./../../../content/speaking/matryoshka-code.mdx" /* webpackChunkName: "component---content-speaking-matryoshka-code-mdx" */),
  "component---content-speaking-maybe-ts-mdx": () => import("./../../../content/speaking/maybe-ts.mdx" /* webpackChunkName: "component---content-speaking-maybe-ts-mdx" */),
  "component---content-speaking-meet-dark-mdx": () => import("./../../../content/speaking/meet-dark.mdx" /* webpackChunkName: "component---content-speaking-meet-dark-mdx" */),
  "component---content-speaking-power-of-preconditions-mdx": () => import("./../../../content/speaking/power-of-preconditions.mdx" /* webpackChunkName: "component---content-speaking-power-of-preconditions-mdx" */),
  "component---content-speaking-progressive-web-apps-demystified-mdx": () => import("./../../../content/speaking/progressive-web-apps-demystified.mdx" /* webpackChunkName: "component---content-speaking-progressive-web-apps-demystified-mdx" */),
  "component---content-speaking-r-3-fts-mdx": () => import("./../../../content/speaking/r3fts.mdx" /* webpackChunkName: "component---content-speaking-r-3-fts-mdx" */),
  "component---content-speaking-react-graphql-chat-workshop-mdx": () => import("./../../../content/speaking/react-graphql-chat-workshop.mdx" /* webpackChunkName: "component---content-speaking-react-graphql-chat-workshop-mdx" */),
  "component---content-speaking-react-with-typescript-mdx": () => import("./../../../content/speaking/react-with-typescript.mdx" /* webpackChunkName: "component---content-speaking-react-with-typescript-mdx" */),
  "component---content-speaking-reducers-and-hooks-in-the-wild-mdx": () => import("./../../../content/speaking/reducers-and-hooks-in-the-wild.mdx" /* webpackChunkName: "component---content-speaking-reducers-and-hooks-in-the-wild-mdx" */),
  "component---content-speaking-refinement-types-mdx": () => import("./../../../content/speaking/refinement-types.mdx" /* webpackChunkName: "component---content-speaking-refinement-types-mdx" */),
  "component---content-speaking-szybciej-taniej-mdx": () => import("./../../../content/speaking/szybciej-taniej.mdx" /* webpackChunkName: "component---content-speaking-szybciej-taniej-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-music-mdx": () => import("./../../../src/pages/music.mdx" /* webpackChunkName: "component---src-pages-music-mdx" */),
  "component---src-pages-notes-tsx": () => import("./../../../src/pages/notes.tsx" /* webpackChunkName: "component---src-pages-notes-tsx" */),
  "component---src-pages-resume-mdx": () => import("./../../../src/pages/resume.mdx" /* webpackChunkName: "component---src-pages-resume-mdx" */),
  "component---src-pages-speaking-tsx": () => import("./../../../src/pages/speaking.tsx" /* webpackChunkName: "component---src-pages-speaking-tsx" */),
  "component---src-pages-theme-tsx": () => import("./../../../src/pages/theme.tsx" /* webpackChunkName: "component---src-pages-theme-tsx" */),
  "component---src-pages-writing-tsx": () => import("./../../../src/pages/writing.tsx" /* webpackChunkName: "component---src-pages-writing-tsx" */)
}

